body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.content{
    /* background-color: #ffe443;   */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Title-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.h1{
    margin: 0 !important;
    padding: 0;
}

.icons {
    width: 30px; 
    height: 30px; 
    transition: all 0.3s ease; 
    transition: all .2s ease-in-out;
}
  

.icons:hover {
    width: 33px; 
    height: 33px; 
    transform: rotate(30deg); 
}
.icon-link{
    width: 10%;
}

.First-box{
    display: flex;
    gap: 10px;
}
.second-box{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}
.third-box{
    width: 100%;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}



.Footer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    pointer-events: none;
}


@media (width<376px){
    .First-box{
            flex-direction: column;
            justify-content: center;
            align-items: center;
    }
    .Title-box{
        align-items: center;
    }

    *{
        text-align: center;
    }
    
}